import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import AccountClockIcon from 'mdi-react/AccountClockIcon';
import FilterMenuIcon from 'mdi-react/FilterMenuIcon';
import AccountCashIcon from 'mdi-react/AccountCashIcon';
import AccountArrowLeftOutlineIcon from 'mdi-react/AccountArrowLeftOutlineIcon';
import AccountOffIcon from 'mdi-react/AccountOffIcon';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import {
  getAllLeadsFetchingActions,
  getAllLeadsSuccess,
} from '../../../redux/actions/leadsPageActions';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { columns } from './LeadsDataColumns';
import './LeadsPage.css';
import { RadioButtonField } from '../../../shared/components/form/RadioButton';
import {
  getLeadManagerId,
  getLeadManagerIdAndStatus,
  getLeadStatus,
  postContractLead,
  postKillClient,
  postProspectLead,
  postQualifyLead,
  postQuoteLead,
} from '../../../utils/api';
import QualifyForm from './QualifyForm/QualifyForm';
import ProspectForm from './ProspectForm/ProspectForm';
import QuoteForm from './QuoteForm/QuoteForm';
import ContractForm from './ContractForm/ContractForm';
import KillForm from './KillForm/KillForm';
import ConfirmModal from './KillForm/ConfirmModal';
import { handleFile } from '../../../utils/helpers';
import _ from 'lodash';
import { parse, stringify } from 'query-string';


const LeadsPage = () => {
  const authUser = useSelector(state => state.auth.user);
  const { leads, loading, errors, totalRecords } = useSelector((state) => state.leads);
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [radioBtn, setRadioBtn] = useState('all');
  const [secondRadioBtn, setSecondRadioBtn] = useState('lead');
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [mesErrors, setMesErrors] = useState([]);
  const [leadId, setLeadId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [checkStatus, setCheckStatus] = useState('');
  const [openKillModal, setOpenKillModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query, setQuery] = useState({ start: 0, limit: 10, search_query: '' });


  const toggle = (val) => {
    setCheckStatus(val.status === checkStatus ? '' : val.status);
    setLeadId(val.id);
  };

  const toggleKill = (val) => {
    setOpenKillModal(!openKillModal);
    setLeadId(val.id);
  };

  const getFilteredLeads = useCallback(updatedQuery => {
    if (radioBtn === 'my' && secondRadioBtn === 'all') {
      getLeadManagerId(authUser.userId, updatedQuery, authUser.accessToken).then(({ data }) =>
        dispatch(getAllLeadsSuccess(data.data)),
      );
      return;
    }
    if (radioBtn === 'all' && secondRadioBtn === 'all') {
      dispatch(getAllLeadsFetchingActions(updatedQuery));
      return;
    }
    if (radioBtn === 'my') {
      getLeadManagerIdAndStatus(authUser.userId, secondRadioBtn, updatedQuery, authUser.accessToken).then(({ data }) =>
        dispatch(getAllLeadsSuccess(data.data)),
      );
      return;
    }
    getLeadStatus(secondRadioBtn, updatedQuery, authUser.accessToken).then(({ data }) =>
      dispatch(getAllLeadsSuccess(data.data)),
    );
  }, [authUser.userId, dispatch, radioBtn, secondRadioBtn, authUser.accessToken])

  const handleSubmitQualifyLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postQualifyLead(leadId, value.description, value.comment, result, authUser.accessToken).then(
      (res) => {
        !res.data.success
          ? setMesErrors(res.data.errors)
          : setRefreshCounter(refreshCounter + 1);
      },
    );

    toggle({ status: '' });
  };

  const handleSubmitProspectLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postProspectLead(
      leadId,
      value.budget?.replace(/[$,]+/g, ''),
      value.start_date,
      value.comment,
      result,
      authUser.accessToken,
    ).then((res) => {
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });

    toggle({ status: '' });
  };

  const handleSubmitQuoteLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postQuoteLead(leadId, value.estimate, value.comment, result, authUser.accessToken).then((res) => {
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });
    toggle({ status: '' });
  };

  const handleSubmitContractLead = async (value) => {
    const result = await handleFile(value.comment_file?.file);

    postContractLead(leadId, value.select?.id, value.comment, result, authUser.accessToken).then(
      (res) => {
        !res.data.success
          ? setMesErrors(res.data.errors)
          : setRefreshCounter(refreshCounter + 1);
      },
    );
    toggle({ status: '' });
  };

  const handleConfirmKillLead = ({ comment, file }) => {
    postKillClient(leadId, comment, file, authUser.accessToken).then((res) => {
      !res.data.success
        ? setMesErrors(res.data.errors)
        : setRefreshCounter(refreshCounter + 1);
    });

    setOpenConfirmModal('');
    setOpenKillModal(false);
  };

  const handleSubmitKillLead = async (value) => {
    const file = await handleFile(value.comment_file?.file);
    setOpenConfirmModal({ ...value, file });
  };

  const handleViewLead = (id) => {
    navigate(`${id}`, {
      state: {
        prevQuery: [{
          query: location.search,
          path: location.pathname,
        }]
      }
    });
  };

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({ start: currentPage, limit: pageLimit }, { arrayFormat: 'comma' })}`);
    getFilteredLeads(updatedQuery);
  }, [currentPage, pageLimit, radioBtn, secondRadioBtn, refreshCounter, getFilteredLeads, query, location.pathname, navigate]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    if (value === "0") return;

    setQuery({ ...query, search_query: value });
  }, 600)


  return (
    <Container>
      <Row>
        <Col xs={12} style={{ display: 'flex', padding: '0 15px' }}>
          <RadioButtonField
            onChange={(val) => setRadioBtn(val)}
            value={radioBtn}
            label="All"
            radioValue="all"
            name="radio_button"
          />
          <RadioButtonField
            onChange={(val) => {
              setRadioBtn(val);
            }}
            value={radioBtn}
            label="My"
            radioValue="my"
            name="radio_button"
          />
        </Col>
        <Col style={{ padding: '0 15px' }}>
          <RadioButtonField
            onChange={(val) => {
              setSecondRadioBtn(val);
            }}
            value={secondRadioBtn}
            label="Leads"
            radioValue="lead"
            name="radio_button_second"
          />
          <RadioButtonField
            onChange={(val) => {
              setSecondRadioBtn(val);
            }}
            value={secondRadioBtn}
            label="Qualified Lead"
            radioValue="qualified_lead"
            name="radio_button_second"
          />
          <RadioButtonField
            onChange={(val) => setSecondRadioBtn(val)}
            value={secondRadioBtn}
            label="Prospect Lead"
            radioValue="prospect_lead"
            name="radio_button_second"
          />
          <RadioButtonField
            onChange={(val) => setSecondRadioBtn(val)}
            value={secondRadioBtn}
            label="Quoted Client"
            radioValue="quoted_client"
            name="radio_button_second"
          />
          <RadioButtonField
            onChange={(val) => setSecondRadioBtn(val)}
            value={secondRadioBtn}
            label="Client"
            radioValue="client"
            name="radio_button_second"
          />
          <RadioButtonField
            onChange={(val) => setSecondRadioBtn(val)}
            value={secondRadioBtn}
            label="Dead Client"
            radioValue="dead_client"
            name="radio_button_second"
          />
          <RadioButtonField
            onChange={(val) => setSecondRadioBtn(val)}
            value={secondRadioBtn}
            label="All"
            radioValue="all"
            name="radio_button_second"
          />
        </Col>
        <Col xs={12} md={12} lg={12}>
          <ConfirmModal
            modal={openConfirmModal}
            toggle={() => setOpenConfirmModal('')}
            onNext={handleConfirmKillLead}
            message={t('leads.kill_form.confirm')}
            color="danger"
          />
          <QualifyForm
            modal={checkStatus === 'lead' || checkStatus === 'dead_client'}
            toggle={toggle}
            onSubmit={handleSubmitQualifyLead}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            leadId={leadId}
          />
          <ProspectForm
            modal={checkStatus === 'qualified_lead'}
            toggle={toggle}
            onSubmit={handleSubmitProspectLead}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            leadId={leadId}
          />
          <QuoteForm
            modal={checkStatus === 'prospect_lead'}
            toggle={toggle}
            onSubmit={handleSubmitQuoteLead}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            leadId={leadId}
          />
          <ContractForm
            modal={checkStatus === 'quoted_client'}
            toggle={toggle}
            onSubmit={handleSubmitContractLead}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
          <KillForm
            modal={openKillModal}
            toggle={toggleKill}
            onSubmit={handleSubmitKillLead}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
          <DataReactTable
            tableName="Leads"
            sortTable
            loading={loading}
            search={true}
            classList={"mt-3"}
            searchValue={getSearchValue}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit,
            }}
            tableOverrodeConfig={{ manualPageSize: [50, 40, 30, 20, 10] }}
            emptyStateMessage={t('empty_states.leads')}
            reactTableData={{
              tableHeaderData: columns,
              tableRowsData: leads?.map((c) => {
                return {
                  ...c,
                  id: `${c.id}`,
                  manager: `${c.manager.name}`,
                  source: `${c?.source}`,
                  actions: (
                    <>
                      {c.status !== 'quoted_client' ? (
                        <>
                          <ReactTooltip id="eyeIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.view')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="eyeIcon"
                            style={{ border: 0, background: 'transparent' }}
                            onClick={() => handleViewLead(c.id)}
                          >
                            <EyeIcon color="#fff" size={18} />
                          </button>
                        </>
                      ) : null}

                      {c.status === 'lead' ? (
                        <>
                          <ReactTooltip id="accountClockIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.qualify')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="accountClockIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <AccountClockIcon
                              color="#fff"
                              size={18}
                              data-testid="qualify-button"
                              onClick={() => toggle(c)}
                            />
                          </button>
                        </>
                      ) : null}

                      {c.status === 'qualified_lead' ? (
                        <>
                          <ReactTooltip id="filterMenuIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.prospect')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="filterMenuIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <FilterMenuIcon
                              color="#fff"
                              size={18}
                              onClick={() => toggle(c)}
                            />
                          </button>
                        </>
                      ) : null}

                      {c.status === 'prospect_lead' ? (
                        <>
                          <ReactTooltip id="accountCashIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.quote')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="accountCashIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <AccountCashIcon
                              color="#fff"
                              size={18}
                              onClick={() => toggle(c)}
                            />
                          </button>
                        </>
                      ) : null}

                      {c.status === 'quoted_client' ? (
                        <>
                          <ReactTooltip id="eyeIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.view')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="eyeIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <EyeIcon
                              onClick={() => handleViewLead(c.id)}
                              color="#000"
                              size={18}
                            />
                          </button>

                          <ReactTooltip id="accountArrowIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.contract')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="accountArrowIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <AccountArrowLeftOutlineIcon
                              color="#000"
                              size={18}
                              onClick={() => toggle(c)}
                            />
                          </button>

                          <ReactTooltip id="killIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.kill')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="killIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <AccountOffIcon
                              color="#000"
                              size={18}
                              onClick={() => toggleKill(c)}
                            />
                          </button>
                        </>
                      ) : null}

                      {c.status === 'dead_client' ? (
                        <>
                          <ReactTooltip id="accountClockIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.qualify')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="accountClockIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <AccountClockIcon
                              onClick={() => toggle(c)}
                              color="#fff"
                              size={18}
                            />
                          </button>
                        </>
                      ) : null}

                      {c.status === 'lead' ||
                        c.status === 'qualified_lead' ||
                        c.status === 'prospect_lead' ||
                        c.status === 'client' ? (
                        <>
                          <ReactTooltip id="killIcon" type="dark">
                            <span style={{ color: 'white' }}>
                              {t('leads.tooltip.kill')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for="killIcon"
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <AccountOffIcon
                              color="#fff"
                              size={18}
                              data-testid="kill-button"
                              onClick={() => toggleKill(c)}
                            />
                          </button>
                        </>
                      ) : null}
                    </>
                  ),
                  rowCustomClass: { className: c.status },
                };
              }),
            }}
          />
          {errors ? <div>{errors}</div> : null}
          <div style={{ color: 'red' }}>{mesErrors?.map((r) => r.message)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default LeadsPage;
